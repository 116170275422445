import React, { useEffect, useState } from 'react';
import SideMenu from './SideMenu'
import TopMenu from './TopMenu'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import { userid, token, type } from '../Components/common/AdminAuth';
import { URL, URLEC2 } from '../Url';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

const Dashboard = () => {
  var date = new Date()
  var todatdate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
  const [isloading, setisloading] = useState(false);
  const [report, setreport] = useState('all');
  const [messageCount, setmessageCount] = useState({
    totalSuccess: 0,
    totalFaild: 0,
    totalTotal: 0
  });
  const [dataList, setDataList] = useState([])
  const [allDataList, setAllDataList] = useState([])
  const [openTicket, setOpenTicket] = useState('')
  const [closeTicket, setCloseTicket] = useState('')
  const [month, setMonth] = useState('')

  useEffect(() => {
    searchList();
  }, [])


  const searchList = async () => {
    let response = await fetch(`https://console.authkey.io/api/support.php?user_id=${userid}&method=retrieve&type=${type}&token=${token}`);
    let result = await response.json();
    if (result.success) {
      // console.log(result.data)
      setAllDataList(result.data);
      setDataList(result.data);
      OpenTicketData(result.data)
      ClosedTicketData(result.data)
      newChart(result.data);
    }
  };

  // useEffect(()=>{
  //   let date = new Date()
  //   let month = String(date.getMonth() + 1).padStart(2, '0')
  //   setMonth(month)
  // })

  const reportHandler = (e) => {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const TodayDate = `${year}-${month}-${day}`;
    console.log(TodayDate)

    const value = e.target.value;
    setreport(value);
    // filterData(value)
    if (value === "today") {
      const matchedData = allDataList.filter(x => x.createdDate.slice(0, 10) === TodayDate);
      setDataList(matchedData);
      OpenTicketData(matchedData)
      ClosedTicketData(matchedData)
    }
    else if (value === "monthly") {
      const matchedMonthlyData = allDataList.filter(x => x.createdDate.substring(5, 7) === TodayDate.substring(5, 7));
      setDataList(matchedMonthlyData);
      OpenTicketData(matchedMonthlyData)
      ClosedTicketData(matchedMonthlyData)
    }
    else if (value === "all") {
      setDataList(allDataList);
      OpenTicketData(allDataList)
      ClosedTicketData(allDataList)
    }
    setmessageCount({
      totalSuccess: '',
      totalFaild: '',
      totalTotal: '',
    });
  }


  const onMonthChange = (e) => {
    setMonth(e.target.value)
    const matchedMonthlyData = allDataList.filter(x => x.createdDate.substring(5, 7) === e.target.value);
    setDataList(matchedMonthlyData);
    OpenTicketData(matchedMonthlyData)
    ClosedTicketData(matchedMonthlyData)
  }

  const OpenTicketData = (data) => {
    if (!data) return;

    const openTicketArray = data.filter(x => x.status === 1);
    setOpenTicket(openTicketArray);
  };

  const ClosedTicketData = (data) => {
    if (!data) return;

    const closeTicketArray = data.filter(x => x.status === 2);
    setCloseTicket(closeTicketArray);
  };

  //   const DailyTicketData = () => {
  //     const date = new Date();

  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, '0');
  //     const day = String(date.getDate()).padStart(2, '0');

  //     const TodayDate = `${year}-${month}-${day}`;
  //     // console.log(TodayDate)

  //     const matchedData = allDataList.filter(x => x.createdDate.slice(0, 10) === TodayDate);
  //     const DailyopenTicketArray = matchedData.filter(x => x.status === 1);
  //     const DailycloseTicketArray = matchedData.filter(x => x.status === 2);

  //     if (matchedData.length > 0) {
  //         console.log("WorkDone:", matchedData); 
  //         setDataList(matchedData);
  //         setOpenTicket(DailyopenTicketArray);
  //         setCloseTicket(DailycloseTicketArray);
  //     } else {
  //         console.log("WorkNotDone");
  //     }

  // }

  // const MonthlyTicketData = () => {
  //   const date = new Date();

  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');

  //   const TodayDate = `${year}-${month}-${day}`;
  //   console.log(TodayDate)
  //   const data =  '2024-09-13'

  //   const matchedMonthlyData = allDataList.filter(x => x.createdDate.substring(5, 7) === TodayDate.substring(5, 7));
  //   const MonthlyopenTicketArray = matchedMonthlyData.filter(x => x.status === 1);
  //   const MonthlycloseTicketArray = matchedMonthlyData.filter(x => x.status === 2);

  //   if (matchedMonthlyData.length > 0) {
  //       console.log("WorkDone:", matchedMonthlyData); 
  //       setDataList(matchedMonthlyData);
  //       setOpenTicket(MonthlyopenTicketArray);
  //       setCloseTicket(MonthlycloseTicketArray);
  //   } else {
  //       console.log("WorkNotDone");
  //   }

  // }

  const adminDashboardData = async () => {
    setisloading(true)
    var APIurl = "";
    switch (report) {
      case "do":
        APIurl = [
          fetch(`${URL}report.php?user_id=${userid}&method=admin_dashboard_count&date_from=${todatdate}&date_to=${todatdate}&token=${token}&user_type=${type}`)
        ]
        break;
      case 'ec2':
        APIurl = [
          fetch(`${URLEC2}ec2/report.php?user_id=${userid}&method=admin_dashboard_count&date_from=${todatdate}&date_to=${todatdate}&token=${token}&user_type=${type}`)
        ]
        break;
      default:
        APIurl = [
          fetch(`${URL}report.php?user_id=${userid}&method=admin_dashboard_count&date_from=${todatdate}&date_to=${todatdate}&token=${token}&user_type=${type}`),
          fetch(`${URLEC2}ec2/report.php?user_id=${userid}&method=admin_dashboard_count&date_from=${todatdate}&date_to=${todatdate}&token=${token}&user_type=${type}`)
        ]
    }
    try {
      let result = await Promise.all(APIurl);
      let data = await Promise.all(result.map((items) => {
        return items.json();
      }))

      console.log('data', data);

      var totalSuccess = 0;
      var totalFaild = 0;
      var totalTotal = 0;
      data.forEach((items) => {
        if (items.success === true) {
          totalSuccess = totalSuccess + parseInt(items.data.delivered)
          totalFaild = totalFaild + parseInt(items.data.failed)
          totalTotal = totalTotal + parseInt(items.data.other) + parseInt(items.data.failed) + parseInt(items.data.delivered)
        } else {
          setmessageCount({
            totalSuccess: '0',
            totalFaild: '0',
            totalTotal: '0',
          })
        }
      })
      setmessageCount({
        totalSuccess: totalSuccess,
        totalFaild: totalFaild,
        totalTotal: totalTotal,
      })
      setisloading(false);
    } catch (e) {
      console.log('error', e);
    }
  }



  const data = [];
  const months = [
    { name: 'Jan', month: '01' },
    { name: 'Feb', month: '02' },
    { name: 'Mar', month: '03' },
    { name: 'Apr', month: '04' },
    { name: 'May', month: '05' },
    { name: 'Jun', month: '06' },
    { name: 'Jul', month: '07' },
    { name: 'Aug', month: '08' },
    { name: 'Sep', month: '09' },
    { name: 'Oct', month: '10' },
    { name: 'Nov', month: '11' },
    { name: 'Dec', month: '12' },
  ];

  months.forEach(({ name, month }) => {
    const filteredTickets = allDataList.filter(x => x.createdDate.slice(5, 7) === month);

    if (filteredTickets.length > 0) {
      const openTicket = filteredTickets.filter(x => x.status === 1).length;
      const closeTicket = filteredTickets.filter(x => x.status === 2).length;

      data.push({
        name,
        totalticket: filteredTickets.length,
        openTicket,
        closeTicket
      });
    }
  });


  const PieChartdata = [
    { name: 'Total Tickets', value: dataList.length },
    { name: 'Open Tickets', value: openTicket.length },
    { name: 'Close Tickets', value: closeTicket.length },
    // { name: 'Group D', value: 200 }, 
  ];


  const COLORS = ['#3B71CA', '#14A44D', '#FF0000', '#FF8042'];

  const Monts = [
    { label: "January", value: '01' },
    { label: "February", value: '02' },
    { label: "March", value: '03' },
    { label: "April", value: '04' },
    { label: "May", value: '05' },
    { label: "June", value: '06' },
    { label: "July", value: '07' },
    { label: "August", value: '08' },
    { label: "September", value: '09' },
    { label: "October", value: '10' },
    { label: "November", value: '11' },
    { label: "December", value: '12' },
  ]

  const newChart = (data) => {
    // console.log(data);

    const newData = data.map(x => x.createdDate.substring(6, 7));

  }

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">

        <SideMenu />

        <div className="layout-page">

          <TopMenu />

          <div className="content-wrapper ">
            <div className="container-xxl flex-grow-1 container-p-y">


              <div className="row">
                <div className='container-p-y'>

                  <div className='d-flex justify-content-between align-items-center'>
                    <h4 className="fw-bold pb-3">Report</h4>

                    <div className="d-flex">
                      {report === "monthly" && (
                        <div className="me-2">
                          <select onChange={onMonthChange} name='selected-month' value={month} className='form-select'>
                            {Monts.map((x) => (
                              <option key={x.value} value={x.value}>{x.label}</option>
                            ))}
                          </select>
                        </div>
                      )}

                      <div>
                        <select onChange={reportHandler} name='selected-report' value={report} className='form-select'>
                          <option value="all">All</option>
                          <option value="monthly">Monthly</option>
                          <option value="today">Today</option>
                        </select>
                      </div>
                    </div>
                  </div>


                  <div className="row">
                    <div className='container-p-y'>
                      <div className="row">
                        <div className="col-lg-4 col-md-12 col-12 mb-4">
                          <div className="card">
                            <div className="card-body">

                              <span className="fw-semibold d-block mb-1">Total Tickets</span>
                              {isloading ?
                                <div className="spinner-grow text-primary" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <h3 className="card-title mb-2">{dataList.length ? dataList.length : 0}</h3>
                              }

                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-12 col-12 mb-4">
                          <div className="card">
                            <div className="card-body">
                              <span className='fw-semibold d-block mb-1'> Open Tickets </span>
                              {isloading ?
                                <div className="spinner-grow text-success" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <h3 className="card-title text-success text-nowrap mb-1">{openTicket.length ? openTicket.length : 0}</h3>
                              }
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-12 col-12 mb-4">
                          <div className="card">
                            <div className="card-body">
                              <span className='fw-semibold d-block mb-1'>Closed Tickets</span>
                              {isloading ?
                                <div className="spinner-grow text-danger" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <h3 className="card-title text-nowrap text-danger mb-1">{closeTicket.length ? closeTicket.length : 0}</h3>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-6  col-6'>
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={data}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Line type="monotone" dataKey="totalticket" stroke="#3B71CA" activeDot={{ r: 8 }} />
                          <Line type="monotone" dataKey="openTicket" stroke="#14A44D" />
                          <Line type="monotone" dataKey="closeTicket" stroke="#FF0000" />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                    <div className='col-md-6  col-6'>
                      <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                          {/* {PieChartdata.map((x)=>{
                        
                      })} */}
                          <Pie
                            data={PieChartdata}
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            dataKey="value"
                            label
                          >
                            {PieChartdata.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-lg-12 mb-4 order-0">
                  <div className="card">
                    <div className="d-flex align-items-end row">
                      <div className="col-sm-7">
                        <div className="card-body">
                          <h5 className="card-title text-primary">Welcome Admin! 🎉</h5>
                          <p className="mb-4">
                            Quick Access Of <span className="fw-bold"> User </span>
                          </p>
                          <Link to="/admin/user-list" className="btn btn-sm btn-primary mr-1">View All User List</Link>
                          <Link to="/admin/summary-report" className="btn btn-sm btn-primary mr-1">User Summary</Link>
                          <Link to="/admin/add-user" className="btn btn-sm btn-primary mr-1">Add User</Link>
                        </div>
                      </div>
                      <div className="col-sm-5 text-center text-sm-left">
                        <div className="card-body pb-0 px-0 px-md-4">
                          <img
                            src='./Images/man-with-laptop-light.png'
                            height="140"
                            alt="View Badge User"
                            data-app-dark-img="illustrations/man-with-laptop-dark.png"
                            data-app-light-img="illustrations/man-with-laptop-light.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div> */}
            </div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
};

export default Dashboard;