import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faChartLine,
  faChartArea,
  faChartBar,
  faChartPie,
  faHistory,
  faTimesCircle,
  faCalendar,
  faGlobe,
  faBars,
  faChevronLeft,
  faChevronRight,
  faList,
  faReply,
} from "@fortawesome/free-solid-svg-icons";

import { type } from "./common/AdminAuth";
import logoUrl from "./Images/logo.png";

const SideMenu = () => {
  const [toggleSideBar, setToggleSideBar] = useState(true);
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const location = useLocation();
  
  const divStyle = {
    borderRadius: "0px 8px 8px 0px",
    backgroundColor: "#007bff",
    color: "white",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  };

  const div2Style = {
    borderRadius: "20px 0px 0px 20px",
    backgroundColor: "red",
    color: "white",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "30px",
    marginTop: "-1px",
    cursor: "pointer",
  };

  const toggleSideMenu = () => {
    setToggleSideBar(!toggleSideBar);
    setOpenSideMenu(true);
  };

  const openSideBar = () => {
    setOpenSideMenu(false);
    setToggleSideBar(true);
  };

 
  return (
    <>
      {toggleSideBar && (
        <>
          <div id="mobile-menu">
            <aside
              id="layout-menu"
              className="layout-menu menu-vertical menu bg-menu-theme"
              style={{ height: "95%" }}
            >
              <div className="app-brand demo">
                <img className="Logo" alt="logo" src={logoUrl} />
                <button className="menu-btn" onClick={() => setToggleSideBar(false)}>
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>
                <div style={div2Style} onClick={toggleSideMenu} className="radius">
                  <FontAwesomeIcon icon={faTimesCircle} />
                </div>
              </div>

              <ul className="menu-inner py-1">
                <li className={location.pathname === "/dashboard" ? "menu-item active" : "menu-item"}>
                  <NavLink className="menu-link" to="/dashboard">
                    <FontAwesomeIcon
                      className="menu-icon tf-icons bx bx-home-circle"
                      icon={faHome}
                    />
                    <div data-i18n="Analytics"> Dashboard </div>
                  </NavLink>
                </li>
                {type === "admin" || type === "emp" ? (
                  <>
                    <li className={location.pathname.includes("/user/ticketlist") || location.pathname.includes("/user/viewticket/:id/:ticketnumber/:parent_email/:title/:email/:department/:replyuserid") ? "menu-item active" : "menu-item"}>
                      <Link to="/user/ticketlist" className="menu-link">
                        <FontAwesomeIcon
                          className="menu-icon tf-icons bx bx-home-circle"
                          icon={faList}
                        />
                        <div data-i18n="Basic"> Ticket List </div>
                      </Link>
                    </li>
                    <li className={location.pathname.includes("/user/quick_reply_list") || location.pathname.includes("/user/quick_reply")    ? "menu-item active" : "menu-item"}>
                      <Link to="/user/quick_reply_list" className="menu-link">
                        <FontAwesomeIcon
                          className="menu-icon tf-icons bx bx-home-circle"
                          icon={faReply}
                        />
                        <div data-i18n="Basic"> Quick Reply List </div>
                      </Link>
                    </li>
                  </>
                ) : null}
              </ul>
            </aside>
          </div>
        </>
      )}

      {openSideMenu && (
        <>
          <div>
            <aside id="layout-menu" className="layout-menu menu bg-menu-theme">
              <div className="app-brand demo">
                <div style={divStyle} onClick={openSideBar} className="border">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </aside>
          </div>
        </>
      )}
    </>
  );
};

export default SideMenu;
