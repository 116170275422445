import React, { useEffect, useState } from 'react'
import SideMenu from './SideMenu'
import TopMenu from './TopMenu'
import { token, type, userid } from './common/AdminAuth'
import { faEdit, faEye, faSearch, faTasks, faTasksAlt, faTicket, faTicketAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAsyncError, useNavigate } from 'react-router-dom'
import { FadeLoader } from 'react-spinners';
import { url } from '../Url'
import Select from 'react-select'
// import DatePicker from "react-datepicker";
import DatePicker from 'react-datepicker';


const TicketList = () => {
    const navigate = useNavigate()
    const [supportList, setSupportList] = useState([])
    const [value, setValue] = useState({})
    const [isOpen, setIsOpen] = useState(false);
    const [isReopen, setISReopen] = useState(false)
    const [searchUser, setSearchUser] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(50);
    const [id, setID] = useState('')
    const [ticketnumber, setTicketNumber] = useState('')
    const [openAssign, setOpenAssign] = useState(false)
    const [employeeList, setEmployeeList] = useState([])
    const [selectEmployee, setSelectEmployee] = useState('')
    const [order, setOrder] = useState("ASC")
    const [dataItem, setDataItem] = useState({})
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        searchList();
        EmployeeList();
    }, [])

    const togglePopup = (id, tkt_number) => {
        setIsOpen(true)
        setID(id)
        setTicketNumber(tkt_number)
    };

    const toggleReopenTicket = (id, tkt_number) => {
        setISReopen(true)
        setID(id)
        setTicketNumber(tkt_number)
    }

    const toggleTicketAssign = (id, tkt_number, parent_email) => {
        setOpenAssign(true)
        setTicketNumber(tkt_number)
        setSelectEmployee({ label: parent_email, value: id })
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value })
    }

    const EmployeeList = async () => {
        let response = await fetch(`https://console.authkey.io/api/employee_user_list.php?user_id=1&method=retrieve&type=admin&token=${token}`)
        let result = await response.json()
        if (result.success == true) {
            // console.log(result.data)
            setEmployeeList(result.data)
        }
    }

    const handleChange = (selectedoption) => {
        setSelectEmployee(selectedoption)
    }

    // const handleInputChange = (event) => {
    //     setSearchUser({ searchdata: event.target.value });
    // };

    const searchList = async () => {
        let response = await fetch(`https://console.authkey.io/api/support.php?user_id=${userid}&method=retrieve&type=${type}&token=${token}`);
        let result = await response.json();
        if (result.success) {
            setSupportList(result.data);
        }
    };

    const SearchUser = async () => {
        let response = await fetch(`https://console.authkey.io/api/support.php?user_id=1&method=search&type=${type}&keyword=${searchUser.searchdata}&token=${token}`);
        let result = await response.json();
        if (result.success == true) {
            setSupportList(result.data);
        }
    };



    // const searchList = async () => {
    //     let response = await fetch(`https://console.authkey.io/api/support.php?user_id=1&method=retrieve&type=admin&token=d83f8067106535063fb7a6aa`)
    //     let result = await response.json()
    //     if (result.success) {
    //         console.log(result.data)
    //         setSupportList(result.data)
    //     }
    // }

    // const closeTicket = async () => {
    //     let response = await fetch(`${url}support.php?user_id=1&type=${type}&id=${id}&method=close&token=d83f8067106535063fb7a6aa&status=2`)
    //     let result = await response.json()
    //     if (result.success == true) {
    //         setIsOpen(false)
    //         searchList();
    //     }
    // }

    // const ReloadTicket = async () => {
    //     let response = await fetch(`${url}support.php?user_id=1&type=${type}&id=${id}&method=close&token=d83f8067106535063fb7a6aa&status=3`)
    //     let result = await response.json()
    //     if (result.success == true) {
    //         setIsOpen(false)
    //         searchList();
    //     }
    // }

    // const SearchUser = async () => {
    //     let response = await fetch(`https://console.authkey.io/api/support.php?user_id=1&method=search&type=${type}&keyword=${searchUser.searchdata}&token=d83f8067106535063fb7a6aa`)
    //     let result = await response.json()
    //     if (result.success == true) {
    //         console.log(result)
    //         setSupportList(result.data)
    //     }
    // }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = supportList.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(supportList.length / recordsPerPage);

    // Function to handle pagination
    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const sorting = (col) => {
        if (order === 'ASC') {
            const sorted = [...supportList].sort((a, b) =>
                a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
            )
            setSupportList(sorted);
            setOrder("DSC")
        }
        if (order === 'DSC') {
            const sorted = [...supportList].sort((a, b) =>
                a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
            )
            setSupportList(sorted);
            setOrder("ASC")
        }
    }

    const SearchTicketByDate = async () => {
        setLoading(true)
        let param = {
            method: 'filter',
            date_from: dataItem.date_from,
            date_to: dataItem.date_to,
            user_id: userid,
            token: token,
            type: type
        }
        console.log(param)
        try {
            let response = await fetch('https://console.authkey.io/api/support.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(param),
            });
            let result = await response.json()
            if(result.success){
                console.log(result.data)
                setLoading(false)
                setSupportList(result.data)

            }
        } catch (error) {
         console.log(error)
        }
    }



    let yesButton = { marginLeft: "130px", backgroundColor: "green", color: "white", border: "hidden", borderRadius: "5px 5px 5px 5px", width: "50px" }
    let noButton = { marginLeft: "5px", backgroundColor: "red", color: "white", border: "hidden", borderRadius: "5px 5px 5px 5px", width: "50px" }
    let headerStyle = { marginTop: "-15px", marginBottom: "10px", border: "hidden", backgroundColor: "black", color: "white", borderRadius: "5px 5px 5px 5px", textAlign: "center", cursor: "pointer" }
    let assign = { marginTop: "15px", marginLeft: "145px", backgroundColor: "green", color: "white", border: "hidden", borderRadius: "5px 5px 5px 5px", width: "100px", height: "30px" }


    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <SideMenu />
                    <div className="layout-page">
                        <TopMenu />
                        <div className="content-wrapper ">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-lg-12 mb-4 order-0">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title text-primary">Filter</h5>
                                                <div className="row">
                                                    <div className="col-md-4 mb-3">
                                                        <label htmlFor="fromDate">From Date</label>
                                                        <input
                                                            type="date"
                                                            id="date_from"
                                                            name="date_from"
                                                            value={dataItem.date_from}
                                                            onChange={onChange}
                                                            className="form-control"
                                                            placeholder="Select Date"
                                                        />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label htmlFor="toDate">To Date</label>
                                                        <input
                                                            type="date"
                                                            id="date_to"
                                                            name="date_to"
                                                            value={dataItem.date_to}
                                                            onChange={onChange}
                                                            className="form-control"
                                                            placeholder="Select Date"
                                                        />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label htmlFor="searchInput">Search</label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                id="searchInput"
                                                                name="searchdata"
                                                                value={searchUser.searchdata}
                                                                onChange={(e) => setSearchUser(e.target.value)}
                                                                className="form-control"
                                                                placeholder="Enter User Name or Ticket ID"
                                                            />
                                                            <div className="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    onClick={SearchTicketByDate}
                                                                    className="btn btn-primary"
                                                                >
                                                                    Search
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div style={{ marginTop: "20px", marginBottom: "20px" }} className="filter-option">
                                    <h6 className="card-subtitle text-muted">Filter </h6>

                                    <div className="row">
                                        <div className="col-md-9">
                                            <div className="input-group mb-2 mr-sm-2">
                                                <div className="input-group-text">
                                                    <FontAwesomeIcon icon={faSearch} />
                                                </div>
                                                <input
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    value={searchUser.searchdata}
                                                    placeholder="Enter User Name or Ticket ID"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 text-center">
                                            <div style={{ height: "37px" }} className="input-group mb-2 mr-sm-2">
                                                <input
                                                    type="button"
                                                    onClick={SearchUser}
                                                    value="Search"
                                                    className="btn mt-1  btn-primary btn-block btn-sm ml-auto"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {loading ? 
                                 <div style={{ display: 'flex', marginLeft: "80vh" }}>
                                 <FadeLoader color="#a3a5dc" loading={loading} size={100} />
                             </div> : 
                             (
                                <div className='row'>
                                    <div className='col-12'>
                                        {/* <table className="styled-table"> */}
                                        <div className="table-responsive">
                                            <table className="styled-table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ cursor: "pointer", fontSize: "13px" }}>Action</th>
                                                        <th style={{ cursor: "pointer", fontSize: "13px" }} onClick={() => sorting("tkt_number")}>Ticket ID</th>
                                                        <th style={{ cursor: "pointer", fontSize: "13px" }}>Ticket Status</th>
                                                        <th style={{ cursor: "pointer", fontSize: "13px" }}>Reply Status</th>
                                                        <th style={{ cursor: "pointer", fontSize: "13px" }} onClick={() => sorting("parent_email")}>Sales Person</th>
                                                        <th style={{ cursor: "pointer", fontSize: "13px" }} onClick={() => sorting("email")}>User Name</th>
                                                        <th style={{ cursor: "pointer", fontSize: "13px" }} onClick={() => sorting("createdDate")}>Created Date/Time</th>
                                                        {/* <th style={{ cursor: "pointer", fontSize: "13px" }} >Closing Date</th>
                                                        <th style={{ cursor: "pointer", fontSize: "13px" }}>Duration</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentRecords.filter((item) => {
                                                        const search = searchUser.toLowerCase();
                                                        return search === '' ? item : item.parent_email.toLowerCase().includes(search) || item.tkt_number.toLowerCase().includes(search)
                                                    }).map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <span
                                                                        type="button"
                                                                        id="dropdownMenuButton"
                                                                        data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="16"
                                                                            height="16"
                                                                            fill="currentColor"
                                                                            className="bi bi-three-dots-vertical"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                        </svg>
                                                                    </span>
                                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                        <a
                                                                            href={`/user/viewticket/${item.id}/${item.tkt_number}/${item.parent_email}/${item.title}/${item.email}/${item.department}/${item.userid}`}
                                                                            className="dropdown-item"
                                                                        >
                                                                            <FontAwesomeIcon icon={faEye} /> View Ticket
                                                                        </a>
                                                                        {/* {item.status === 1 || item.status === 3 ? (
                                                                            <a
                                                                                className="dropdown-item"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => togglePopup(item.id, item.tkt_number)}
                                                                            >
                                                                                <FontAwesomeIcon icon={faTimes} /> Close Ticket
                                                                            </a>
                                                                        ) : null}
                                                                        {item.status === 2 ? (
                                                                            <a
                                                                                className="dropdown-item"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => toggleReopenTicket(item.id, item.tkt_number)}
                                                                            >
                                                                                <FontAwesomeIcon icon={faTicket} /> Reopen Ticket
                                                                            </a>
                                                                        ) : null} */}
                                                                        <a
                                                                            className="dropdown-item"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => toggleTicketAssign(item.id, item.tkt_number, item.parent_email)}
                                                                        >
                                                                            <FontAwesomeIcon icon={faTasksAlt} /> Assign Ticket
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td> <a style={{ color: "blue", marginLeft: "-20px" , fontSize:"13px"}} href={`/user/viewticket/${item.id}/${item.tkt_number}/${item.parent_email}/${item.title}/${item.email}/${item.department}/${item.userid}`}
                                                                className="dropdown-item"
                                                            >{item.tkt_number} </a></td>
                                                            <td style={{ textAlign: "center" , fontSize:"13px" }}>
                                                                {item.status === 1 && (
                                                                    <span className="badge bg-danger text-white">
                                                                        Open
                                                                    </span>
                                                                )}
                                                                {item.status === 2 && (
                                                                    <span className="badge bg-success text-white">
                                                                        Closed
                                                                    </span>
                                                                )}
                                                                {item.status === 3 && (
                                                                    <span className="badge bg-warning text-white">
                                                                        Reopen
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td style={{fontSize:"13px"}}>{item.answer_status === 1 ? <p style={{ color: "green" }}>Answered</p> : <p style={{ color: "red" }}>Unanswered</p>}</td>
                                                            <td style={{fontSize:"13px"}}>{item?.parent_email?.split('@')[0]}</td>
                                                            <td style={{fontSize:"13px"}}>{item?.email?.split('@')[0]}</td>
                                                            <td style={{fontSize:"13px"}}>{item.createdDate}</td>
                                                            {/* <td style={{fontSize:"12px"}}>{item.createdDate}</td>
                                                            <td style={{fontSize:"12px"}}>{item.createdDate}</td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <ul className="pagination">
                                                {Array.from({ length: totalPages }).map((_, index) => (
                                                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => handlePagination(index + 1)}>
                                                            {index + 1}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        {/* </table> */}


                                    </div>
                                </div>
                             )}
                                
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {openAssign && (
                <div className="popup">
                    <div className="assign-popup-content">
                        <div className='row' onClick={() => setOpenAssign(false)}>
                            <div className='col-6'>
                                <b>Assign Ticket</b>
                            </div>
                            <div className='col-6'>
                                <p style={{ marginLeft: "80px" }}>{ticketnumber}</p>
                            </div>
                        </div>
                        <span className="close">&times;</span>
                        <div className='row' style={{ marginTop: "10px" }}>
                            <div className="col-12">
                                <label>Select Employee</label>
                                <Select
                                    options={employeeList.map((user) => ({
                                        label: user.email,
                                        value: user.id
                                    }))}
                                    name="Employee"
                                    value={selectEmployee} // This should be an object containing the selected user's label and value
                                    onChange={handleChange}
                                    placeholder="All Employee"
                                    isSearchable
                                />

                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <button className="btn mt-1 btn-secondary btn-sm text-center" style={assign} onClick={() => setOpenAssign(false)}>Assign</button>
                            </div>
                        </div>

                    </div>
                </div>
            )}




        </>
    )
}

export default TicketList
