import React, { useState } from 'react';
import TopMenu from './TopMenu';
import SideMenu from './SideMenu';
import SunEditor, { buttonList } from 'suneditor-react';
import { name, token, userid } from './common/AdminAuth';
import { useNavigate } from 'react-router-dom';

const QuickReply = () => {
  const navigate = useNavigate()
  let blankDataItem = {
    title: "",
    view_by: {}
  }
  const [formData, setFormData] = useState(blankDataItem);
  const [description, setDescription] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      user_id: userid,
      title: formData.title,
      token: token,
      content: description,
      created_by_name: name,
      view_by: formData.view_by,
      method: "create"
    };
    try {
      let saveReplies = await fetch('https://console.authkey.io//api/support_quick_reply.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (saveReplies.ok) {
        console.log("Replies Saved Successfully");
        setFormData(blankDataItem);
        // Redirect to a new page after successful save
        window.location.href = '/user/quick_reply_list'; // Replace with your desired URL
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleMessageChange = (content) => {
  //   setDescription(content);
  // };

  const GoBack = () => {
    navigate('/user/quick_reply_list')
  }


  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideMenu />
        <div className="layout-page">
          <TopMenu />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title text-primary mb-4">Create Quick Reply</h5>
                      <form >
                        <div className='form-row'>
                          <div className='col-md-6'>
                            <label htmlFor='title' className='form-label'>Title</label>
                            <input type='text' id='title' name='title' className='form-control' value={formData.title} onChange={handleChange} placeholder='Enter Title'/>
                          </div>
                          <div className='col-md-6'>
                            <label htmlFor='description' className='form-label'>View By</label>
                            <select className="form-select" name='view_by' value={formData.view_by} onChange={handleChange} >
                              <option value='1'>Public</option>
                              <option value='2'>Self</option>
                            </select>
                          </div>
                        </div>
                        {/* <div className='mb-3 mt-4'>
                          <label htmlFor='description' className='form-label'>Description</label>
                          <SunEditor
                            height="100"
                            setContents={description}
                            onChange={handleMessageChange}
                            setOptions={{
                              buttonList: buttonList.complex,
                            }}
                            placeholder="Please type here..."

                          />
                        </div> */}
                        <div className="mb-3 mt-3">
                          <label htmlFor="content" className="form-label">Description</label>
                          <textarea
                            className="form-control"
                            id="content"
                            name='content'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter Description ..."
                            style={{height:"20vh"}}
                          ></textarea>
                        </div>

                        <button type='submit' onClick={handleSubmit} className='btn btn-primary float-right'>Submit</button>
                        <button type="button" onClick={GoBack} class="btn btn-secondary float-right mr-2">Back</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuickReply;
