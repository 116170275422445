import React, { useEffect, useState } from 'react';
import SideMenu from './SideMenu';
import TopMenu from './TopMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertBox from './AlertBox';
import { faEdit, faPlus, faSearch, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { token, userid } from './common/AdminAuth';

const QuickReplyList = () => {
  const [QuickList, setQuickList] = useState([]);
  const [quickReplyPopup, setQuickReplyPopup] = useState(false);
  const [data, setData] = useState({});
  const [alert, setAlert] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setId] = useState("")
  const [title, setTitle] = useState("")
  const [DeletePopup, setDeletePopup] = useState(false)
  const [searchData, setSearchData] = useState('')
  const [recordsPerPage] = useState(5);

  useEffect(() => {
    fetchQuickReplyList();
  }, []);

  const fetchQuickReplyList = async () => {
    let Param = {
      method: "retrieve",
      user_id: userid,
      token: token
    };
    try {
      let retrieveApi = await fetch("https://console.authkey.io//api/support_quick_reply.php", {
        method: "Post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(Param)
      });
      let responseData = await retrieveApi.json();
      if (responseData.success) {
        // console.log(responseData.data);
        setQuickList(responseData.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = QuickList.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(QuickList.length / recordsPerPage);

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const showAlert = (type, message) => {
    setAlert({ type, message });
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };


  const QuickReplyData = async (id) => {
    setQuickReplyPopup(true);
    let param = {
      method: "retrieveid",
      user_id: userid,
      token: token,
      id: id
    };
    try {
      let api = await fetch("https://console.authkey.io//api/support_quick_reply.php", {
        method: "Post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(param)
      });
      let response = await api.json();
      if (response.success) {
        console.log(response.data[0]);
        setData(response.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const isValid = () => {
    if (!data.title || data.title.trim() === "") {
      showAlert("error", "Please Enter Title");
      return false;
    }
    if (!data.content || data.content.trim() === "") {
      showAlert("error", "Please Enter Description");
      return false;
    }
    return true;
  };

  const UpdateData = async (e) => {
    e.preventDefault();
    if (isValid()) {
      let param = {
        content: data.content,
        title: data.title,
        view_by: data.view_by,
        method: "update",
        user_id: userid,
        token: token,
        id: data.id
      };
      try {
        let api = await fetch("https://console.authkey.io//api/support_quick_reply.php", {
          method: "Post",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(param)
        });
        if (api.ok) {
          console.log("Data Updated Successfully");
          setQuickReplyPopup(false);
          fetchQuickReplyList();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const ToggleDelete = (id, title) => {
    setDeletePopup(true);
    setId(id)
    setTitle(title)
  }

  const DeleteData = async () => {
    let Param = {
      method: "delete",
      user_id: userid,
      token: token,
      id: id
    };
    try {
      let api = await fetch("https://console.authkey.io//api/support_quick_reply.php", {
        method: "Post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(Param)
      });
      if (api.ok) {
        console.log("Data Deleted Successfully");
        setQuickReplyPopup(false);
        fetchQuickReplyList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideMenu />
          <div className="layout-page">
            <TopMenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className='row'>
                  <div className='col-6'>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                      </div>
                      <input
                        type="text"
                        id="searchInput"
                        name="searchdata"
                        value={searchData}
                        onChange={(e) => setSearchData(e.target.value)}
                        className="form-control"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <div className='col-6'>
                  <p className="demo-inline-spacing text-right ">
                  <Link className="btn btn-primary me-1" to="/user/quick_reply">
                    <FontAwesomeIcon icon={faPlus} /> Add Quick Reply
                  </Link>
                </p>
                  </div>
                </div>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered table-hover">
                          <thead className="table-dark">
                            <tr>
                              <th style={{ color: "white", fontSize: "12px" }}>Action</th>
                              <th style={{ color: "white", fontSize: "12px" }}>Title</th>
                              <th style={{ color: "white", fontSize: "12px" }}>Content</th>
                              <th style={{ color: "white", fontSize: "10px" }}>ViewBy</th>
                              <th style={{ color: "white", fontSize: "12px" }}>Created</th>
                              <th style={{ color: "white", fontSize: "10px" }}>CreatedBy</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentRecords.reverse().filter((x)=>{
                               return searchData.toLowerCase() === '' ? x : x.title.toLowerCase().includes(searchData);
                            }).map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="dropdown">
                                    <span
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-three-dots-vertical"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                      </svg>
                                    </span>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                      <a
                                        className="dropdown-item"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => QuickReplyData(item.id)}
                                      >
                                        <FontAwesomeIcon icon={faEdit} /> Update
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => ToggleDelete(item.id, item.title)}
                                      >
                                        <FontAwesomeIcon icon={faTrash} /> Delete
                                      </a>
                                    </div>
                                  </div>
                                </td>
                                <td style={{ fontSize: "13px" }}>{item.title}</td>
                                <td style={{ fontSize: "13px" }}>{item.content.split('.')[0]}</td>
                                <td style={{ fontSize: "13px" }}>
                                  {item.view_by === 1 || item.view_by === 0 ? (
                                    <span className="badge bg-success">Public</span>
                                  ) : (
                                    <span className="badge bg-info">Self</span>
                                  )}
                                </td>
                                <td style={{ fontSize: "13px" }}>{item.created}</td>
                                <td style={{ fontSize: "13px" }}>{item.created_by_name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-center">
                          {Array.from({ length: totalPages }).map((_, index) => (
                            <li
                              key={index}
                              className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                            >
                              <button
                                className="page-link"
                                onClick={() => handlePagination(index + 1)}
                              >
                                {index + 1}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </nav>
                      {quickReplyPopup && (
                        <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} aria-labelledby="popupLabel" aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="popupLabel">Edit: {data.title}</h5>
                                <button type="button" className="btn-close" onClick={() => setQuickReplyPopup(false)} aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                {alert && (
                                  <AlertBox
                                    type={alert.type}
                                    message={alert.message}
                                    onClose={() => setAlert(null)}
                                  />
                                )}
                                <form>
                                  <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Title</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="title"
                                      name='title'
                                      value={data.title || ''}
                                      onChange={handleChange}
                                      placeholder="Enter Title"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label htmlFor="content" className="form-label">Description</label>
                                    <textarea
                                      className="form-control"
                                      id="content"
                                      name='content'
                                      value={data.content || ''}
                                      onChange={handleChange}
                                      placeholder="Enter Content"
                                    ></textarea>
                                  </div>
                                  <div className="mb-3">
                                    <label htmlFor="view_by" className="form-label">View By</label>
                                    <select
                                      id="view_by"
                                      name='view_by'
                                      value={data.view_by || ''}
                                      onChange={handleChange}
                                      className="form-select"
                                    >
                                      <option value="">Select</option>
                                      <option value="1">Public</option>
                                      <option value="2">Self</option>
                                    </select>
                                  </div>
                                  <button type="button" onClick={UpdateData} className="btn btn-success me-2 float-right">Update</button>
                                  <button type="button" onClick={() => setQuickReplyPopup(false)} className="btn btn-secondary float-right mr-2">Close</button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {DeletePopup && (
                        <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} aria-labelledby="popupLabel" aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="popupLabel">Delete: {title}</h5>
                                <button type="button" className="btn-close" onClick={() => setDeletePopup(false)} aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                {alert && (
                                  <AlertBox
                                    type={alert.type}
                                    message={alert.message}
                                    onClose={() => setAlert(null)}
                                  />
                                )}
                                <div className='row'>
                                  <div className='col-12'>
                                    <h5>Are You Sure You Want to Delete</h5>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-12'>
                                    <button type="button" onClick={DeleteData} className="btn btn-danger me-2 float-right">Delete</button>
                                    <button type="button" onClick={() => setDeletePopup(false)} className="btn btn-secondary float-right mr-2">Close</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickReplyList;
